.welcome-box-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.welcome-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 350px;
    height: 350px;
    background-color: rgba(129, 185, 0, 0.6);
    border: solid 5px rgba(129, 185, 0, 0.8);
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
    cursor: default;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}
.welcome-box:hover {
    background-color: rgba(129, 185, 0, 0.85);
    border: solid 5px #81b900;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.6);
}

/* {{{ LOGO */
.welcome-box > .logo {
    text-align: center;
    font-weight: 100;
}
.welcome-box .logo span {
    display: block;
}
.welcome-box .logo span.name {
    font-size: 45px;
    text-shadow: 0 0 10px #000;
    font-family: "Great Vibes", cursive;
    color: #fff;
}
.welcome-box .logo span.slogan {
    font-size: 15px;
    color: #000;
    font-weight: 300;
}
/* /LOGO }}} */


/* {{{ SOCIALS */
.welcome-box .social-logos {
    display: flex;
}
.welcome-box .social-logos .social {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 50px;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 5px;
}
.welcome-box .social-logos img {
    display: block;
    max-width: 100px;
}
.welcome-box .social-logos .spotify {
    background-color: #000;
}
.welcome-box .social-logos .youtube {
    background-color: #fff;
}
.welcome-box .social-logos .plus {
    font-size: 30px;
    color: #000;
    margin-top: 8px;
    margin-left: 10px;
    margin-right: 8px;
    text-shadow: 0 10px 10px rgba(255, 255, 255, 0.2);
}
/* /LOGO }}} */


/* {{{ LOGIN */
.welcome-box .login {
    text-align: center;
}
.login-loading-container {
    display: none;
}
.welcome-box .login .login-button {
    transition: all 0.2s ease-in-out;
    font-size: 35px;
    font-weight: 100;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.9);
    text-shadow: 0 0 10px #000;
    cursor: pointer;
}
.welcome-box .login .login-button:hover {
    text-decoration: none;
    text-shadow: 0 0 10px #fff;
}
.welcome-box .login .login-button:hover + .login-via {
    color: #000;
}
.welcome-box .login .login-via {
    transition: all 0.2s ease-in-out;
    display: block;
    margin-top: -8px;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.5px;
    color: #444;
}
.welcome-box .login .loading {
    color: #fff;
    font-size: 60px;
}

.welcome-box .login .access-denied .icon {
    font-size: 40px;
    color: #fff;
}
.welcome-box .login .access-denied .error-message {
    display: flex;
    align-items: center;
    padding: 5px 7px;
    border-radius: 5px;
    background-color: rgba(204, 24, 30, 1);
    font-weight: 100;
    font-size: 20px;
    color: #fff;
}
.welcome-box .login .access-denied .error-message .icon {
    margin-right: 10px;
}
/* /LOGIN }}} */

.welcome-box .logo span.slogan,
.welcome-box .login .login-button {
    font-weight: 200;
}
.welcome-box .login .login-via {
    font-weight: 400;
    color: #222;
}
