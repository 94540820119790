.navigation {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 250px;
    height: 100%;
    background-color: rgba(129, 185, 0, 0.5);
    border-right: solid 2px #81b900;
    padding: 0;
    cursor: default;
}

/* {{{ LOGO */
.navigation .brand {
    text-align: center;
    padding: 0;
    margin: 20px 0 0;
}
.navigation .brand > a {
    transition: all 0.2s ease-in-out;
    font-family: "Great Vibes", cursive;
    font-weight: 700;
    font-size: 45px;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.navigation .brand a:hover {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
    text-decoration: none;
}
/* /LOGO }}} */

.navigation .show-menu {
    display: none;
    position: absolute;
    top: 8px;
    right: 10px;
    font-weight: 100;
    font-size: 35px;
    color: #fff;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.navigation .show-menu:hover, .navigation .show-menu.active {
    text-shadow: 0 0 10px #fff;
}

.navigation ul.icons {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    text-align: center;
}
.navigation ul.icons li {
    display: inline-block;
    margin-left: 7px;
    margin-right: 7px;
}
.navigation ul.icons li i {
    font-size: 35px;
    transition: all 0.2s ease-in-out;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
}
.navigation ul.icons li i.active {
    color: white;
    text-shadow: 0 0 10px #fff;
}
.navigation ul.icons li:hover a i {
    color: white;
}


.navigation .playlists-container {
    display: block;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    border-top: solid 1px rgba(129, 185, 0, 0.2);
}
.navigation .playlists-title {
    margin: 0;
    padding-bottom: 10px;
    text-align: center;
    color: #fff;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    font-weight: 100;
    font-size: 19px;
    text-transform: uppercase;
}
.navigation .playlists-container .loading {
    display: block;
    text-align: center;
    margin-top: 40px;
    font-size: 90px;
    color: rgba(255, 255, 255, 0.8);
}
.navigation .playlists-container ul.list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.navigation .playlists-container ul.list li {
    position: relative;
}
.navigation .playlists-container ul.list li a,
.navigation .playlists-container ul.list li.no-playlists {
    display: block;
    transition: all 0.2s ease-in-out;
    color: #fff;
    font-weight: 100;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding: 10px 20px;
    border-bottom: solid 1px rgba(129, 185, 0, 0.2);
}
.navigation .playlists-container ul.list li a {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navigation .playlists-container ul.list li a .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 8px;
    margin-left: 10px;
    font-weight: 100;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.3);
}
.navigation .playlists-container ul.list li a:hover {
    background-color: rgba(129, 185, 0, 0.2);
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    text-decoration: none;
}
.navigation .playlists-container ul.list li a:hover .badge {
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.8);
}
.navigation .playlists-container ul.list li a.active {
    background-color: #81b900;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.navigation .playlists-container ul.list li a.active .badge {
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
}

.navigation .playlists-container ul.list li.no-playlists {
    text-align: center;
    border-bottom: none;
}
.navigation .playlists-container ul.list li.no-playlists .icon {
    display: inline-block;
    margin-top: 10px;
    font-size: 40px;
}

@media screen and (max-width: 1441px) {
    .navigation {
        width: 230px;
    }

    .navigation ul.icons {
        margin: 10px 0;
    }

    .navigation ul.icons li {
        margin-left: 7px;
        margin-right: 7px;
    }

    .navigation ul.icons li a i {
        font-size: 28px;
    }

    .navigation .playlists-container ul.list li a {
        font-size: 12px;
        padding-top: 7px;
        padding-left: 10px;
        padding-bottom: 7px;
    }
}
@media screen and (min-width: 320px) and (max-width: 650px) and (orientation: portrait) {
    .navigation {
        position: relative;
        width: 100%;
        height: auto;
        border-right: 0;
        border-bottom: solid 2px #81b900;
    }

    .navigation .playlists-title {
        display: none;
    }

    .navigation .brand {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .navigation .brand a {
        font-size: 25px;
    }

    .navigation .show-menu {
        display: block;
    }

    .navigation ul.icons {
        display: none;
    }

    .navigation .playlists-container {
        display: none;
    }

    .navigation.show-menu {
        height: 100%;
    }

    .navigation.show-menu .playlists-container {
        display: block;
    }

    .navigation.show-menu .playlists-container .list li a {
        padding: 15px;
    }

    .navigation.show-menu ~ .main-box {
        display: none;
    }
}

.b-safari .navigation .show-menu {
    font-weight: 200;
}
.b-safari .navigation .playlists-title {
    font-weight: 200;
}
.b-safari .navigation .playlists-container ul.list li a {
    font-weight: 200;
}
.b-safari .navigation .playlists-container ul.list li .badge {
    font-weight: 200;
}
