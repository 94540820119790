.tracks-box {
    display: flex;
    transition: all 0.2s ease-in-out;
    flex-direction: column;
}
.tracks-box-header {
    display: flex;
    justify-content: space-between;
}
.tracks-box .title .tracks-count {
    margin-left: 10px;
    font-size: 11px;
    border-bottom: 0;
    color: rgba(255, 255, 255, 0.5);
}

.tracks-search-form {
    position: relative;
}
.tracks-search-form input {
    border-radius: 60px;
    width: 150px;
    font-weight: 100;
    font-size: 15px;
    padding: 5px 15px;
    background-color: rgba(255, 255, 255, 0.2);
    border: solid 2px transparent;
    transition: all 0.2s ease-in-out;
    color: rgba(255, 255, 255, 0.5);
}
.tracks-search-form input:focus {
    width: 300px;
    background-color: rgba(129, 185, 0, 0.7);
    color: #fff;
}
.tracks-search-form input:focus::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
}
.tracks-search-form.loading input {
    padding-right: 35px;
}
.tracks-search-form.loading i.loading {
    position: absolute;
    top: 2px;
    right: 5px;
    color: #fff;
    font-size: 30px;
}


@media screen and (max-width: 1441px) {
    .tracks-search-form input:focus {
        width: 230px;
    }
}
@media screen and (min-width: 320px) and (max-width: 650px) and (orientation: portrait) {
    .tracks-search-form {
        display: none;
    }
}
.b-safari .tracks-search-form input {
    font-weight: 200;
}
