* {
    outline: 0 !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

input, input:before, input:after {
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
}

html, body {
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
    user-select: none;
    overflow: hidden;
    position: fixed;
}

.hide {
    display: none;
}

.clear {
    float: none;
    clear: both;
}

.icon {
    line-height: 0px;
}

p {
    margin: 0px;
    padding: 0px;
    color: #fff;
    font-weight: 100;
    font-size: 20px;
}

a, a:hover, a:active, a:visited, a:focus {
    text-decoration: none;
}

body > * {
    width: 100%;
    height: 100%;
}
body .container {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    padding: 0px;
}

.height-100x100 {
    height: 100%;
}

.overlay-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.75);
}
.overlay-loading .icon {
    font-size: 170px;
    color: #81b900;
    text-shadow: 0px 0px 10px #81b900;
}

.page-background {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    background-image: url(/src/img/12626746194_cc9de11c9a_k.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
.page-background > .overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
    background-image: url(/src/img/video_over.png);
    background-repeat: repeat;
}

.main-box {
    display: flex;
    position: relative;
    margin-left: 250px;
    height: 100%;
    cursor: default;
}
.main-box .title {
    margin: 0px;
    margin-bottom: 30px;
    padding: 0px;
}
.main-box .title span {
    font-weight: 100;
    text-transform: uppercase;
    color: #fff;
    font-size: 30px;
    border-bottom: solid 2px #81b900;
}

.first-content-box {
    position: relative;
    overflow: hidden;
    width: 700px;
    background-color: rgba(0, 0, 0, 0.8);
    border-right: solid 2px rgba(255, 255, 255, 0.8);
    padding: 25px;
}
.first-content-box.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.first-content-box.loading .icon {
    font-size: 100px;
    color: #81b900;
    text-shadow: 0px 0px 10px #81b900;
}

.sorry {
    text-align: center;
    font-weight: 100;
    font-size: 25px;
    text-transform: uppercase;
    color: #fff;
}
.sorry .icon {
    display: block;
    font-size: 70px;
}

.quote-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}
.quote-box {
    transition: background-color 0.2s ease-in-out;
    -webkit-transform: rotate(-3deg);
    padding: 20px;
    max-width: 700px;
    cursor: default;
    text-align: left;
}
.quote-box .quote {
    font-family: "Parisienne", cursive;
}
.quote-box .quote h4 {
    transition: all 0.2s ease-in-out;
    font-size: 50px;
    margin: 0px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.8);
}
.quote-box .quote span {
    display: block;
    text-align: right;
    font-weight: 100;
    font-size: 20px;
    font-style: italic;
    /* color: rgba(0, 0, 0, 0.4); */
    color: rgba(255, 255, 255, 0.5);
}
.quote-box:hover {
    /* background-color: rgba(129, 185, 0, 0.8); */
    /* -webkit-transform: rotate(360deg); */
}
.quote-box:hover h4 {
    color: #fff;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

.settings h2 {
    float: left;
}
.settings h4 {
    float: right;
    margin: 0px;
    padding: 0px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 100;
    letter-spacing: 0.5px;
}
.settings h4 .smile {
    margin-left: 2px;
    color: #81b900;
    font-weight: 300;
}
.settings .fetch-data {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    border: 0px;
    border-radius: 20px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 15px;
    padding-bottom: 5px;
    background-color: rgba(255, 255, 255, 0.9);
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    color: #000;
    border: solid 1px transparent;
}
.settings .fetch-data .icon {
    font-size: 30px;
    color: #81b900;
    margin-right: 10px;
}
.settings .fetch-data:hover {
    border: solid 1px #81b900;
    box-shadow: 0px 0px 20px #81b900;
    background-color: white;
}
.settings .fetch-results {
    list-style: none;
    padding: 0;
    margin: 20px 0 0;
    color: #fff;
    font-weight: 200;
    font-size: 16px;
    letter-spacing: 0.5px;
}
.settings .fetch-results li {
    margin-bottom: 5px;
}
.settings .fetch-results li .count {
    color: #81b900;
    font-weight: bold;
}

.tracks-box .tracks-container {
    flex: 1;
    overflow: auto;
}
.tracks-box .no-tracks .icon {
    color: #81b900;
}
.tracks-box .tracks {
    display: table;
    font-weight: 100;
    width: 100%;
}
.tracks-box .tracks .track-item {
    position: relative;
    display: table-row;
}
.tracks-box .tracks .track-item a {
    position: relative;
    display: table-row;
}
.tracks-box .tracks .track-item.header .cell {
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.9);
    font-size: 12px;
}
.tracks-box .tracks .track-item.header .cell.last {
    text-align: right;
}
.tracks-box .tracks .track-item .cell {
    display: table-cell;
    /* border-right: solid 1px rgba(255, 255, 255, 0.25); */
    border-bottom: solid 1px rgba(255, 255, 255, 0.25);
    padding: 6px 10px;
    vertical-align: middle;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    letter-spacing: 0.5px;
    transition: all 0.2s ease-in-out;
}
.tracks-box .tracks .track-item .cell.first {
    padding-left: 5px;
}
.tracks-box .tracks .track-item .cell.last {
    border-right: 0px;
    padding-left: 0px;
    padding-right: 5px;
}
.tracks-box .tracks .track-item .cell.action {
    padding-right: 0px;
}
.tracks-box .tracks .track-item .cell.action .icon {
    font-size: 20px;
    cursor: pointer;
}
.tracks-box .tracks .track-item .cell.track {
    width: 300px;
}
.tracks-box .tracks .track-item .cell.duration {
    text-align: right;
}
.tracks-box .tracks .track-item:not(.header):hover .cell, .tracks-box .tracks .track-item.active .cell {
    background-color: rgba(129, 185, 0, 0.6);
    color: white;
    text-shadow: 0px 0px 5px #000;
}
.tracks-box .tracks .track-item.active .cell {
    margin-top: -1px;
    border-top: solid 1px #81b900;
    border-bottom: solid 1px #81b900;
    background-color: rgba(129, 185, 0, 0.7);
}

.page-background-author {
    right: 10px;
    bottom: 5px;
    position: fixed;
    z-index: 4;
    font-size: 9px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.3);
    cursor: default;
}
.page-background-author a {
    transition: all 0.2s ease-in-out;
    color: inherit;
}
.page-background-author a:hover {
    color: white;
    text-decoration: none;
}

body.welcome-icon .welcome-box .logo {
    position: relative;
    top: 50%;
    margin-top: -42px;
}
body.welcome-icon .welcome-box .logo span.name {
    font-size: 75px;
}
body.welcome-icon .welcome-box .logo span.slogan {
    display: none;
}
body.welcome-icon .welcome-box .login,
body.welcome-icon .welcome-box .spotify-youtube-logos {
    display: none;
}
body.welcome-icon .page-background-author {
    display: none;
}

@media screen and (max-width: 1441px) {
    .main-box {
        margin-left: 230px;
    }

    .tracks-box {
        width: 550px;
    }
    .tracks-box .tracks .track-item.header .cell {
        font-size: 12px;
    }
    .tracks-box .tracks .track-item .cell {
        padding-top: 4px;
        padding-left: 6px;
        padding-right: 6px;
        padding-bottom: 4px;
        font-size: 12px;
        letter-spacing: 0.5px;
    }
    .tracks-box .tracks .track-item .cell.action {
        width: 15px;
    }
    .tracks-box .tracks .track-item .cell.track {
        width: 250px;
    }
    .tracks-box .tracks .track-item .cell.artist {
        width: 150px;
    }
    .tracks-box .tracks .track-item .cell.duration {
        width: 50px;
    }
}
@media screen and (max-width: 1225px) {
    .main-box {
        flex-direction: column;
    }

    .first-content-box {
        width: auto;
    }

    .tracks-box {
        width: auto;
    }
    .tracks-box.with-details {
        border-right: solid 2px #cc181e;
    }
}
@media screen and (max-width: 1225px) and (orientation: portrait) {
    .tracks-box.with-details {
        height: 400px;
    }
}
@media screen and (max-width: 1225px) and (orientation: landscape) {
    .tracks-box.with-details {
        height: 300px;
    }
}
@media screen and (min-width: 320px) and (max-width: 650px) and (orientation: portrait) {
    .container {
        display: flex;
        flex-direction: column;
    }

    .main-box {
        margin-left: 0;
        overflow: hidden;
    }

    .first-content-box {
        padding: 0;
        border-right: 0;
    }

    .quote-box {
        max-width: auto;
        cursor: default;
        top: 10px;
        left: 0px;
        margin: auto !important;
    }
    .quote-box .quote h4 {
        font-size: 30px;
    }

    .tracks-box .title {
        display: none;
    }
    .tracks-box.with-details {
        height: 0px;
        border-right: 0px;
        border-bottom: 0px;
    }
    .tracks-box .tracks-container {

    }
    .tracks-box .tracks .track-item {
        cursor: pointer;
    }
    .tracks-box .tracks .track-item:hover .cell {
        background-color: transparent !important;
    }
    .tracks-box .tracks .track-item .cell {
        padding: 15px;
    }
    .tracks-box .tracks .track-item .cell.action {
        padding-left: 7px;
    }
    .tracks-box .tracks .track-item .cell.action .icon {
        font-size: 15px;
    }
    .tracks-box .tracks .track-item .cell.duration {
        padding-right: 7px;
    }
}

.b-firefox .settings .fetch-data {
    position: relative;
    width: 470px;
}
.b-firefox .settings .fetch-data .icon {
    position: absolute;
    top: -1px;
    left: 10px;
    margin-right: 0px;
}
.b-firefox .settings .fetch-data span {
    display: inline-block;
    margin-left: 30px;
}

.b-safari .main-box .title span {
    font-weight: 200;
}
.b-safari .overlay-loading .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -85px;
    margin-left: -85px;
}
.b-safari .sorry {
    font-weight: 200;
}
.b-safari .settings h4 {
    font-weight: 200;
}
.b-safari .settings .fetch-results {
    font-weight: 200;
}
.b-safari .tracks-box .tracks {
    font-weight: 200;
}
