.sync-outer-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
    display: flex;
    flex-direction: column;
}
.sync-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: solid 3px #81b900;
    background-color: rgba(0, 0, 0, 0.6);
}
.sync-footer {
    position: absolute;
    bottom: 30px;
    border-radius: 5px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.2);
}
.sync-footer .syncing-message {
    text-align: center;
    color: #81b900;
    font-weight: bold;
    font-size: 18px;
}
.sync-footer .wait-message {
    text-align: center;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
}
.sync-footer .loading-bar-container {
    border-radius: 5px;
    margin-top: 10px;
    border: solid 1px #81b900;
    width: 100%;
    height: 10px;
}
.sync-footer .loading-bar-container .loading-bar {
    transition: width 0.5s;
    border-radius: 4px;
    background-color: #81b900;
    width: 0;
    height: 100%;
}
.sync-footer .loading-bar-container .loading-bar-pre-auth {
    transition: width 3s;
    width: 15%;
}
.sync-container .icon.loading {
    text-align: center;
    font-size: 150px;
    color: #81b900;
    text-shadow: 0 0 10px #81b900;
}
