.details-box {
    flex: 1;
    position: relative;
    overflow: auto;
    height: 100%;
    padding: 25px;
    background-color: rgba(204, 24, 30, 0.2);
    border-right: solid 2px #cc181e;
}
.details-box.loading {
    display: flex;
    align-items: center;
    justify-content: center;
}
.details-box.loading .icon {
    font-size: 100px;
    color: #cc181e;
    text-shadow: 0 0 10px #cc181e;
}

/* {{{ HEADER */
.details-box-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.details-box-header .video-title {
    padding: 0;
    margin: 0 10px 0 0;
    border-bottom: solid 2px #cc181e !important;
    font-size: 30px;
    font-weight: 100;
    text-transform: uppercase;
    color: #fff;
}
.details-box-header .video-title .icon {
    margin-right: 10px;
    color: #cc181e;
}
.details-box-header .close-icon {
    cursor: pointer;
    color: #fff;
    font-size: 40px;
    transition: all 0.2s ease-in-out;
}
.details-box-header .close-icon:hover {
    color: #cc181e;
}
/* /HEADER }}} */


/* {{{ ADDITIONAL VIDEOS */
.details-box .track-additional-videos {
    list-style: none;
    margin: 0 0 20px;
    padding: 0;
}
.details-box .track-additional-videos li {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
}
.details-box .track-additional-videos li a {
    display: inline-block;
    padding: 5px 8px 5px 7px;
    transition: all 0.2s ease-in-out;
    background-color: rgba(255, 255, 255, 0.15);
    color: #fff;
}
.details-box .track-additional-videos li a:hover {
    background-color: rgba(255, 255, 255, 0.3);
}
.details-box .track-additional-videos li a.active {
    background-color: rgba(204, 24, 30, 0.8);
    color: #fff;
}
/* /ADDITIONAL VIDEOS }}} */


.details-box .video-box {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.15);
    text-align: center;
}
.details-box .video-box .loading {
    color: #fff;
    font-size: 70px;
}
.details-box .video-box .no-video i {
    font-size: 60px;
}
.details-box .video-box .reasons {
    display: inline-block;
    margin-top: 40px;
    padding: 40px;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 2px;
    box-shadow: 0px 0px 10px #cc181e;
}
.details-box .video-box .reasons ul {
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.details-box .video-box .reasons .provide-url {
    margin-top: 20px;
    padding-top: 20px;
    border-top: solid 1px rgba(0, 0, 0, 0.1);
}
.details-box .video-box .reasons .provide-url form {
    display: inline-block;
    margin-top: 10px;
    border-radius: 60px;
    box-shadow: 0px 0px 10px #fff;
    font-size: 14px!important;
}
.details-box .video-box .reasons .provide-url form .url,
.details-box .video-box .reasons .provide-url form .button {
    float: left;
    border: 0px;
    padding: 5px 15px;
}
.details-box .video-box .reasons .provide-url form .url {
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
    width: 255px;
    color: #000;
}
.details-box .video-box .reasons .provide-url form .button {
    padding-left: 10px;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
    border-left: solid 1px #888;
    background-color: #ccc;
    text-transform: uppercase;
}
.details-box .video-box .reasons .provide-url form .button:hover {
    background-color: #bbb;
}
.details-box .video-box .reasons .provide-url form .button .icon {
    color: #000;
    font-size: 14px!important;
}
.details-box .video-box .reasons .provide-url .provide-url-error {
    margin-top: 5px;
    margin-left: 15px;
    color: #cc181e;
    font-weight: bold;
}
.details-box .video-box iframe {
    display: block;
    margin: auto;
    width: 100%;
    height: 480px;
}
.details-box .lyrics-box {
    margin-top: 30px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    max-height: 420px;
    overflow: auto;
}

@media screen and (max-width: 1441px) {
    .details-box .video-box iframe {
        height: 315px;
    }
}
@media screen and (max-width: 1225px) {
    .main-box {
        display: flex;
        flex-direction: column;
    }
}
@media screen and (max-width: 1225px) and (orientation: portrait) {
    .details-box .video-box iframe {
        height: 400px;
    }
}
@media screen and (max-width: 1225px) and (orientation: landscape) {
    .details-box .video-box iframe {
        height: 300px;
    }
}
@media screen and (min-width: 320px) and (max-width: 650px) and (orientation: portrait) {
    .details-box {
        border-right: 0px;
        padding: 20px;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .details-box .video-title span {
        font-size: 20px;
    }
    .details-box .close-box .icon {
        font-size: 35px;
    }
    .details-box .video-box {
        padding: 5px;
    }
    .details-box .video-box iframe {
        height: 210px;
    }
    .details-box .video-box .reasons {
        display: none;
    }
}
